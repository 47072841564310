<template>
  <section>
    <div class="custom_page" v-if="allowPageConfiguration">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group form-group-sm">
            <label for="">
              {{ $t("custom_page") }}
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                ref="page"
                v-model="page"
                :disabled="busy"
              />
              <div
                class="input-group-addon btn"
                @click.stop.prevent="page = defaultPage"
              >
                <i class="fa fa-undo"></i>
              </div>
              <div
                class="input-group-addon btn"
                @click.stop.prevent="resetPage"
              >
                <i class="fa fa-close"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormExtendedProperties v-model="items" />
    <div class="footer">
      <div class="row">
        <div class="col-sm-6 text-left">
          <button
            class="btn btn-default"
            @click.stop.prevent="restore"
            :disabled="busy"
          >
            <i class="fa fa-undo"></i> {{ $t("restore") }}
          </button>
        </div>
        <div class="col-sm-6 text-right">
          <button
            class="btn btn-primary"
            :disabled="!payload || busy"
            @click.stop.prevent="save"
          >
            <i class="fa fa-refresh fa-spin" v-if="busy"></i>
            <i class="fa fa-save" v-else></i>
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContractService from "@/services/contract.js";
import { defaultItem } from "@/components/registration/form-extended-properties.vue";
import FormExtendedProperties from "@/components/registration/form-extended-properties.vue";

const defaultPage = "/static/common/pages/form-equipment.html";

export default {
  name: "FormConnectorExtendedProperties",
  components: {
    FormExtendedProperties
  },
  data() {
    return {
      items: [],
      item: defaultItem(),
      page: "",
      busy: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    payload() {
      let payload = {
        portal_data: JSON.parse(
          JSON.stringify(this?.contract?.portal_data || {})
        ),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      let items = JSON.parse(JSON.stringify(this.items));
      let inserted = {};
      items = items
        .filter((item) => {
          if (item.name && !(item.name in inserted)) {
            inserted[item.name] = true;
            return true;
          }
          return false;
        })
        .map((item, ix) => {
          item.index = ix;
          return item;
        });
      payload.portal_data.equipment_extended_properties = items;
      payload.portal_data.equipment_extended_page =
        this.page || (items.length ? this.defaultPage : "");
      return payload;
    },
    btnNewLine() {
      let items = this.items || [];
      if (!items.length || items[items.length - 1].name) return true;
      return false;
    },
    allowPageConfiguration() {
      return false; // TODO: it will be a new acl rule
    },
    defaultPage() {
      return this.$root?.config?.equipment_extended_page || defaultPage;
    }
  },
  watch: {
    contract: {
      handler(n) {
        if (n) {
          this.restore();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    restore() {
      let items = JSON.parse(
        JSON.stringify(
          this.contract?.portal_data?.equipment_extended_properties || []
        )
      );
      // items.push(this.item);
      this.$set(this, "items", items);
      this.page = this.contract?.portal_data?.equipment_extended_page || "";
    },
    resetPage() {
      this.page = "";
      this.$nextTick(() => {
        if (this.$refs.page) this.$refs.page.focus();
      });
    },
    success() {
      let options = {
        singleton: true,
        type: "success",
        icon: "fa-check",
        iconPack: "fontawesome",
        position: "",
        duration: ""
      };
      if (this.$root.config.alert && "success" in this.$root.config.alert) {
        Object.assign(options, this.$root.config.alert);
      }
      options.position = (options["success"].options.position || "")
        .replace(/(1\-|\-1)/g, "")
        .toLowerCase();
      options.duration = options["success"].options.duration;
      this.$toasted.show(
        this.$t("you_have_saved_n_items", { count: 1 }),
        options
      );
    },
    save() {
      if (this.busy) return;
      let payload = this.payload;
      if (!payload) return;
      this.busy = true;
      this.srv.updateContract(payload).then((response) => {
        this.busy = false;
        if (response && typeof response == "object" && response.id) {
          this.item = defaultItem();
          this.$store.dispatch("user/configureUserContract"); // config contract
          this.success();
        } else {
          this.$swal({
            title: self.$t("item_could_not_be_saved"),
            text: self.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    }
  },
  mounted() {
    this.srv = new ContractService();
  }
};
</script>

<style scoped>
.footer {
  text-align: center;
  margin-top: 60px;
  /* border-top: 1px solid #cdcdcd; */
  padding-top: 10px;
}

.footer > div > button {
  margin: 0 10px 0 0;
}

.form-group {
  margin: 0;
}

div.custom_page {
  margin-bottom: 30px;
}
</style>
