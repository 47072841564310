<template>
  <div class="input-mask-wrapper">
    <input
      :ref="`{id}_below`"
      type="text"
      class="form-control input-mask-below"
      :class="{ disabled: disabled }"
      v-model="placeholderMask"
      :style="{ width: width ? width : 'inherited' }"
      disabled="true"
      enterkeyhint="done"
    />
    <input
      :ref="`{id}_over`"
      type="text"
      class="form-control input-mask-over"
      :class="{ disabled: disabled }"
      :id="id"
      :maxlength="maxLength"
      v-model="formattedValue"
      :placeholder="currentPlaceholderMask"
      :style="{ width: width ? width : 'inherited' }"
      @focus="setCaretPositionEvent"
      @keydown="onKeyDown"
      @paste.prevent="onPaste"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { onlyNumbers } from "@/plugins/utils.js";

export default {
  name: "InputMasked",
  props: {
    id: {
      type: String,
      required: false,
      default: () => ""
    },
    value: {
      type: String,
      required: false,
      default: () => ""
    },
    mask: {
      type: [String, Array],
      required: false,
      default: () => ""
    },
    type: {
      type: String,
      required: false
    },
    focus: {
      type: Boolean,
      default: false,
      required: false
    },
    width: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      iValue: this.value,
      original: this.value,
      placeholderMask: "",
      currentPlaceholderMask: "",
      hasFocus: false
    };
  },
  computed: {
    formattedValue: {
      get() {
        return this.$utils.formatMask(this.maskList, this.iValue, true);
      },
      set(value) {
        this.iValue = onlyNumbers(value);
        this.setPlaceholderMask();
        this.$emit("input", this.iValue);
      }
    },
    maskList() {
      if (this.mask) {
        if (!Array.isArray(this.mask)) {
          return [this.mask];
        } else {
          return this.mask;
        }
      }
      switch (this.type) {
        case "cpf_cnpj":
          return ["___.___.___-__", "__.___.___/____-__"];
        case "phone":
          return ["(__) ____-____", "(__) _____-____"];
        case "zipcode":
          return ["__.___-___"];
        default:
          return [this.$tc("invalid_mask")];
      }
    },
    caretPosition() {
      return this.formattedValue.length;
    },
    maxLength() {
      if (!this.maskList || !this.maskList.length) {
        return 0;
      }
      return this.maskList[this.maskList.length - 1].length;
    }
  },
  watch: {
    hasFocus(n) {
      if (this.$el) {
        this.$emit(n ? "focus" : "blur");
      }
    },
    iValue(n) {
      this.setPlaceholderMask();
    },
    value: {
      handler(n) {
        this.iValue = onlyNumbers(n).slice(0, this.maxLength);
        this.setPlaceholderMask();
      },
      immediate: true
    }
  },
  methods: {
    setCaretPositionEvent() {
      this.$utils.setCaretPosition(this.$el, this.caretPosition);
    },
    setPlaceholderMask() {
      let mask = this.$utils.currentMask(this.maskList, this.iValue);
      let result = this.formattedValue;
      this.currentPlaceholderMask = "";

      if (result == "") {
        result = mask;
        this.currentPlaceholderMask = mask;
      } else if (result.length < mask.length) {
        result += mask.slice(result.length, mask.length);
      }
      this.placeholderMask = result;
    },
    onKeyDown(e) {
      let code = e.keyCode || e.which;
      if (code >= 96 && code <= 105) code -= 48;
      let char = String.fromCharCode(code);

      const KEY_BACKSPACE = 8;
      const KEY_TAB = 9;
      const KEY_ENTER = 13;
      const KEY_DELETE = 46;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;
      const KEY_HOME = 36;
      const KEY_END = 35;
      const KEY_C = 67;
      const KEY_V = 86;
      const KEY_X = 88;

      let pos_start = e.target.selectionStart;
      let new_pos = pos_start;
      let pos_end = e.target.selectionEnd;
      let value = e.target.value;

      if (pos_start == pos_end) {
        let value_caret_pos = onlyNumbers(
          e.target.value.slice(0, pos_start)
        ).length;

        if (code == KEY_DELETE) {
          this.iValue =
            this.iValue.slice(0, value_caret_pos) +
            this.iValue.slice(value_caret_pos + 1, this.iValue.length);
          this.setPlaceholderMask();
          this.$nextTick(() => {
            this.$utils.setCaretPosition(e.target, new_pos);
          });
          this.$emit("input", this.iValue);
          e.preventDefault();
          e.stopPropagation();
          return;
        } else if (code == KEY_BACKSPACE) {
          this.iValue =
            this.iValue.slice(0, value_caret_pos - 1) +
            this.iValue.slice(value_caret_pos, this.iValue.length);
          this.setPlaceholderMask();
          new_pos = Math.max(0, new_pos - 1);
          while (value.charAt(new_pos) == "") {
            new_pos--;
            if (new_pos <= 0) break;
          }
          this.$nextTick(() => {
            this.$utils.setCaretPosition(e.target, new_pos);
          });
          this.$emit("input", this.iValue);
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }

      if (
        !(
          code == KEY_TAB ||
          code == KEY_ENTER ||
          code == KEY_BACKSPACE ||
          code == KEY_DELETE ||
          code == KEY_HOME ||
          code == KEY_END ||
          code == KEY_LEFT ||
          code == KEY_RIGHT ||
          (e.ctrlKey && (code == KEY_C || code == KEY_V || code == KEY_X)) ||
          onlyNumbers(char) != ""
        )
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    onPaste(e) {
      this.iValue = onlyNumbers(e.clipboardData.getData("text")).slice(
        0,
        this.emptySlots
      );
      this.setPlaceholderMask();
      this.$emit("input", this.iValue);
    }
  },
  mounted() {
    if (this.focus) {
      this.$nextTick(() => {
        if (this?.$refs[this.id + "_over"].focus) {
          this.$refs[this.id + "_over"].focus();
        }
      });
    }
  }
};
</script>

<style scoped>
div.input-mask-wrapper {
  position: relative;
}
input.input-mask-below {
  color: gray;
  background-color: white;
}
input.input-mask-below.disabled {
  color: darkgray;
  background-color: #ebeff2;
}
input.input-mask-over {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent;
}
input.input-mask-over.disabled {
  color: darkgray;
}
</style>
