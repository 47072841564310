<template>
  <section class="dashboard-settings">
    <div class="container">
      <div class="content-header">
        <div class="no-select hidden-xs hidden-sm">
          <span class="clicable" @click.stop.prevent="expanded = !expanded">
            <span class="toggle"></span>
            {{ $t("configurations") }}
          </span>
        </div>
        <div class="input-group-btn hidden-md hidden-lg">
          <button
            type="button"
            class="btn dropdown-toggle"
            style="background-color: transparent;padding: 15px 0 0 0; font-size: 16pt;"
            data-toggle="dropdown"
            aria-expanded="true"
          >
            <span class="toggle"></span>
            {{ $t("configurations") }}
          </button>
          <div v-if="!menuOptions" class="text-center padding-20">
            <Spin />
          </div>
          <ul class="dropdown-menu">
            <li
              v-for="(option, ix) in menuOptions"
              :key="ix"
              :class="{
                active: option.id == active
              }"
              :title="option.title"
            >
              <a href="" @click.prevent="active = option.id">
                <i :class="option.icon"></i>
                <span v-if="expanded">
                  {{ option.title }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row content-row" style="padding-bottom: 50px;">
        <div
          class="col-sm-3 col-lg-2 hidden-sm hidden-xs"
          ref="menu"
          v-if="expanded"
          style="margin-right: -15px"
        >
          <div class="box box-solid side-menu">
            <div class="box-header with-border" v-if="expanded">
              <h3 class="box-title">
                <span class="">
                  {{ $t("options") }}
                </span>
              </h3>
            </div>
            <div class="box-body no-padding" style="">
              <div v-if="!menuOptions" class="text-center padding-20">
                <Spin />
              </div>
              <ul class="nav nav-pills nav-stacked">
                <li
                  v-for="(option, ix) in menuOptions"
                  :key="ix"
                  :class="{
                    active: option.id == active
                  }"
                  :title="option.title"
                >
                  <a href="" @click.stop.prevent="active = option.id">
                    <i :class="option.icon"></i>
                    <span v-if="expanded" class="hidden-sm hidden-xs">
                      {{ option.title }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
        <div
          :class="[expanded ? 'col-md-9' : 'col-xs-12', 'main-content']"
          ref="main"
          style="margin-right: -15px;"
        >
          <div v-if="!menuOptions" class="box box-solid">
            <div class="box-body text-center padding-20">
              <Spin />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'profile'">
            <div class="box-header with-border header">
              <i class="fa fa-user"></i> {{ $t("user_profile") }}
            </div>
            <div class="box-body">
              <UserProfileForm />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'security'">
            <div class="box-header with-border header">
              <i class="fa fa-lock"></i> {{ $t("security") }}
            </div>
            <div class="box-body">
              <UserSecurityForm />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'preferences'">
            <div class="box-header with-border header">
              <i class="fa fa-star"></i> {{ $t("preferences") }}
            </div>
            <div class="box-body">
              <UserPreferencesForm :sidebar="false" />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'contract_settings'">
            <div class="box-header with-border header">
              <i class="fa fa-cogs"></i> {{ $t("contract_settings") }}
            </div>
            <div class="box-body">
              <ContractSettings />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'extended_properties'">
            <div class="box-header with-border header">
              <i class="fa fa-key"></i> {{ $t("extended_properties") }}
            </div>
            <div class="box-body">
              <FormConnectorExtendedProperties />
            </div>
          </div>
          <div class="box box-solid" v-if="active == 'access_key'">
            <div class="box-header with-border header">
              <i class="fa fa-key"></i> {{ $tc("access_key", 2) }}
            </div>
            <div class="box-body">
              <FormContractTokens />
            </div>
          </div>
          <UserPlanPanel
            v-if="active == 'billing'"
            :class="{ 'md-view': meetWidthThreshold && expanded }"
            :tab="innerTab"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserProfileForm from "@/components/user-profile-form";
import UserPlanPanel from "@/components/user-plan/user-plan-panel.vue";
import UserPreferencesForm from "@/components/control-sidebar/property-editors/user-preferences-form.vue";
import FormConnectorExtendedProperties from "@/components/registration/form-connector-extended-properties.vue";
import FormContractTokens from "@/components/registration/form-contract-tokens.vue";
import ContractSettings from "@/components/contract-settings.vue";
import MixinMobile from "@/project/mixin-mobile";
import UserSecurityForm from "@/components/user-security-form";
import Spin from "@/components/spin";

export default {
  name: "DashboardSettings",
  mixins: [MixinMobile],
  props: {
    initial: {
      type: String,
      default: "profile",
      required: false
    },
    innerTab: {
      type: String
    }
  },
  components: {
    UserProfileForm,
    UserPreferencesForm,
    UserPlanPanel,
    FormConnectorExtendedProperties,
    FormContractTokens,
    ContractSettings,
    UserSecurityForm,
    Spin
  },
  data() {
    return {
      active: "",
      expanded: !this.$utils.isMobile(),
      containerHeight: "auto",
      widthThreshold: { min: 991, max: 1199 }
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isRoot() {
      return (this.$store.getters["user/loggedUser"] || {}).is_root || false;
    },
    menuOptions() {
      if (!this.contract) return;
      let menuOptions = [];

      if (this.$store.getters["user/isAccountLocked"]) {
        if (this.isRoot) {
          menuOptions.push({
            id: "billing",
            title: this.$t("my_plan"),
            icon: "fa fa-credit-card"
          });
        }
        this.$set(this, "active", "billing");
        return menuOptions;
      }

      menuOptions.push({
        id: "profile",
        title: this.$t("profile"),
        icon: "fa fa-user"
      });

      let otp = this?.$root?.config?.references?.otp;

      if (
        otp &&
        otp?.otp_config?.required &&
        !(otp?.otp_config?.app_enabled || otp?.otp_config?.email_enabled)
      ) {
        otp.otp_config.email_enabled = true;
      }

      if (
        otp &&
        otp.otp_enabled &&
        (otp?.otp_config?.app_enabled || otp?.otp_config?.email_enabled)
      ) {
        menuOptions.push({
          id: "security",
          title: this.$t("security"),
          icon: "fa fa-lock"
        });
      }

      menuOptions.push({
        id: "preferences",
        title: this.$t("preferences"),
        icon: " fa fa-star"
      });

      if (this.$can("manage", "ContratoEscrita")) {
        menuOptions.push(
          ...[
            {
              id: "contract_settings",
              title: this.$t("contract_settings"),
              icon: "fa fa-cogs"
            },
            {
              id: "extended_properties",
              title: this.$t("extended_properties"),
              icon: "fa fa-list-ul"
            }
          ]
        );
        if (this?.$root?.config?.custom_access_key) {
          menuOptions.push({
            id: "access_key",
            title: this.$tc("access_key", 2),
            icon: "fa fa-key"
          });
        }
      }

      if (this.isRoot) {
        menuOptions.push({
          id: "billing",
          title: this.$t("my_plan"),
          icon: "fa fa-credit-card"
        });
      }

      return menuOptions;
    }
  },
  watch: {
    menuOptions(n) {
      this.setActive();
    },
    active: {
      handler(n) {
        let path = `/dashboard/settings/${n.replace("_", "-")}`;
        if (path != this.$route.path) {
          this.$router.push(path);
        }
        this.$root.$emit("controlSidebar:setContent", null);
      }
    },
    initial(n) {
      if (n && this.menuOptions.some((p) => p.id == n)) {
        this.active = n;
      }
    }
  },
  methods: {
    setActive() {
      if (!this.menuOptions || this.active) return;
      this.active = this.menuOptions.some(
        (p) => p.id == this.initial.replace("-", "_")
      )
        ? this.initial.replace("-", "_")
        : "profile";
    }
  },
  mounted() {
    this.setActive();
  }
};
</script>

<style lang="scss" scoped>
.dashboard-settings {
  // prevent content "jump" when scrollbar is visible
  padding-left: calc(100vw - (100% + 60px));
}

.box {
  margin-bottom: 0;
}

.box-header {
  font-size: 20px;
}

.nav {
  overflow-x: auto;
}

.nav > li > a {
  padding: 10px 5px;
  font-size: 10pt;
  white-space: normal;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: initial;
  background-color: initial;
}

div.content-header {
  margin-bottom: 10px;
  padding: 15px 0 0 0;
  font-size: 16pt;
}

.content-row {
  display: flex;
  align-items: stretch;
  min-height: 84vh;
}
.main-content {
  flex-grow: 1;
}
.main-content > .box {
  height: 100%;
}

.toggle {
  background-color: transparent;
  background-image: none;
  color: #666;
  padding: 0 6px;
  font-family: fontAwesome;
}

.toggle::before {
  content: "\f0c9";
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.side-menu {
  height: 100%;
}

.padding-20 {
  padding: 20px;
}

@media (min-width: 1400px) {
  .container {
    width: 1376px;
  }
}
.dropdown-toggle:active {
  outline-width: 0;
  background-color: transparent;
  box-shadow: none;
}
</style>
